.u1-card1 {
    display: flex;
    flex-direction: column;
    box-shadow:var(--shadow);
    border-radius:var(--radius);
}
.u1-card1.u1-card1 > * {
    margin:0;
    padding: calc(var(--gap)/2) var(--gap);
}
.u1-card1 > :first-child { border-top-left-radius: inherit; border-top-right-radius: inherit; }
.u1-card1 > :last-child { border-bottom-right-radius: inherit; border-bottom-left-radius: inherit; }

.u1-card1 > .-body {
    flex:1 0 auto;
}



/* todo if needed
a.u1-card1 {
    color:inherit;
    text-decoration:none;
    transition: box-shadow .1s;
}
a.u1-card1:hover {
    xbox-shadow: var(--shadow-x) var(--shadow-y) 1.6rem -.5rem rgba(23, 23, 23, 0.35),
                var(--shadow-x) var(--shadow-y) 1.0rem -.7rem rgba(23, 23, 23, 0.2);
}
*/